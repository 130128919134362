
// Vuex
import { mapState, mapGetters } from 'vuex';

// scss
import scssVars from '~/assets/scss/shared/_variables.scss';

// Utils
import { scrollToWithBlackout, remToPx } from '@@/common/assets/js/utils/common-utils';

// components
import HomeHero from '~/components/pages/home/HomeHero.vue';
import HomeAbout from '~/components/pages/home/HomeAbout.vue';
import HomeQuote from '~/components/pages/home/HomeQuote.vue';
import HomePurpose from '~/components/pages/home/HomePurpose.vue';
import HomeResidence from '~/components/pages/home/HomeResidence.vue';
import HomeOrganizer from '~/components/pages/home/HomeOrganizer.vue';
import HomeOpportunities from '~/components/pages/home/HomeOpportunities.vue';
import HomeEvents from '~/components/pages/home/HomeEvents.vue';
import HomeTelegram from '~/components/pages/home/HomeTelegram.vue';
import HomeFormats from '~/components/pages/home/HomeFormats.vue';
import SubscribeForm from '~/components/common/SubscribeForm.vue';
import HomeFeedback from '~/components/pages/home/HomeFeedback.vue';
import HomeVisitEvents from 'landing/components/pages/home/HomeVisitEvents.vue';
import HomeAdBlock from 'landing/components/pages/home/HomeAdBlock.vue';

export default {
    name: 'IndexPage',

    components: {
        HomeAdBlock,
        HomeHero,
        HomeAbout,
        HomeQuote,
        HomePurpose,
        HomeResidence,
        HomeOrganizer,
        HomeOpportunities,
        HomeEvents,
        HomeTelegram,
        HomeFormats,
        SubscribeForm,
        HomeFeedback,
        HomeVisitEvents,
    },

    async asyncData({ error, $api, $axios, $sentry }) {
        try {
            const [
                mainPageRes,
                clubRes,
                feedbackRes,
                companiesRes,
                offersRes,
                eventsRes,
                eventsSpecs,
                eventsFacets,
                socialsRes,
                adRes,
                visitOurEventsRes,
            ] = await Promise.all([
                $axios.$get($api.mainPage.data),
                $axios.$get($api.club.data),
                $axios.$get($api.feedback.list),
                $axios.$get($api.companies.list),
                $axios.$get($api.offers.list),
                $axios.$get($api.events.list, {
                    params: {
                        is_practice: false,
                        actual: true,
                    },
                }),
                $axios.$get($api.events.specs),
                $axios.$get($api.events.facets, {
                    params: {
                        is_practice: false,
                        actual: true,
                    },
                }),
                $axios.$get($api.socials.list),
                $axios.$get($api.advertiser.data),
                $axios.$get($api.events.visitOurEvents),
            ]);

            $sentry.captureException(new Error('test error in index page in landing on client'));

            return {
                mainPageData: mainPageRes || {},
                aboutData: clubRes || [],
                feedbackData: feedbackRes || [],
                companiesData: companiesRes || [],
                offersData: offersRes || [],
                activeOffer: offersRes?.[0]?.id || '',
                events: eventsRes?.results || [],
                // делаю объектом потому что хочу
                eventsSpecs: eventsSpecs?.reduce((acc, el) => {
                    acc[el.name] = el.choices;
                    return acc;
                }, {}) || {},

                // то же самое
                eventsFacets: eventsFacets?.facets?.reduce((acc, el) => {
                    acc[el.name] = el.choices;
                    return acc;
                }, {}) || {},

                eventsCount: eventsRes?.count || 0,

                socials: socialsRes,
                adData: adRes,
                visitOurEventsRes,
            };
        } catch (e) {
            console.warn('[MainPage/asyncData] request failed: ', e);
            return error({ statusCode: 404 });
        }
    },

    data() {
        return {
            // общие данные страницы
            mainPageData: {},

            aboutData: [],

            feedbackData: [],

            companiesData: [],

            offersData: [],
            activeOffer: '',

            // данные блока событий
            events: [],
            eventsSpecs: {},
            eventsFacets: {},
            eventsValues: {
                actual: true,
                is_practice: false,
                type: [],
            },

            eventsCount: 0,

            cancelTokens: [],

            socials: [],

            // Рекламный блок
            adData: {},

            visitOurEventsRes: {},
        };
    },

    head() {
        return {
            link: [
                {
                    rel: 'canonical',
                    href: `${this.domain}${this.$route.path}`,
                },
            ],

            meta: [
                {
                    hid: 'og:url',
                    name: 'og:url',
                    content: `${this.domain}${this.$route.fullPath}`,
                },
            ],
        };
    },

    computed: {
        ...mapState('header', ['activeMenuItemId']),

        ...mapGetters({
            domain: 'domain/getDomainAddress',
        }),

        purposeData() {
            return this.mainPageData?.blocks?.find(el => el.slug === 'for') || {};
        },

        opportunitiesData() {
            return this.mainPageData?.blocks?.find(el => el.slug === 'opportunities') || {};
        },

        aboutOrganizer() {
            return this.mainPageData?.blocks?.find(el => el.slug === 'organizer') || {};
        },

        stagesData() {
            return this.mainPageData?.blocks?.find(el => el.slug === 'stages') || {};
        },

        membersFeedback() {
            return this.feedbackData?.filter(el => !el.is_separate) || [];
        },

        quote() {
            return this.feedbackData?.find(el => el.is_separate) || {};
        },

        eventsCountInTitle() {
            return this.mainPageData?.event_count_per_year || '';
        },
    },

    mounted() {
        if (this.activeMenuItemId) {
            this.$nextTick(function() {
                this.scrollToId(this.activeMenuItemId);
            });
        }
    },

    methods: {
        async updateEvents(e) {
            try {
                this.resetCurrentRequests();

                this.cancelTokens = [this.$axios.CancelToken.source(), this.$axios.CancelToken.source()];
                const [eventsRes, eventsFacets] = await Promise.all([
                    this.$axios.$get(this.$api.events.list, {
                        params: {
                            ...e,
                        },
                        cancelToken: this.cancelTokens[0].token,
                    }),
                    this.$axios.$get(this.$api.events.facets, {
                        params: {
                            ...e,
                        },
                        cancelToken: this.cancelTokens[1].token,
                    }),
                ]);

                const facets = eventsFacets?.facets?.reduce((acc, el) => {
                    acc[el.name] = el.choices;
                    return acc;
                }, {}) || {};

                const filteredTypes = e.type?.filter(el => facets.type.includes(el));

                if (filteredTypes.length !== e.type?.length) {
                    await this.updateEvents({ ...e, type: filteredTypes });

                    return;
                }

                this.eventsValues = { ...e };

                this.events = eventsRes?.results || [];
                this.eventsCount = eventsRes?.count || 0;

                this.eventsFacets = eventsFacets?.facets?.reduce((acc, el) => {
                    acc[el.name] = el.choices;
                    return acc;
                }, {}) || {};
            } catch (e) {
                console.warn('[MainPage/updateEvents] request failed ', e);
            }
        },

        resetCurrentRequests() {
            if (this.cancelTokens?.length) {
                this.cancelTokens.forEach(el => el?.cancel('Operation canceled by user'));
                this.cancelTokens = [];
            }
        },

        handleChangeOffer(e) {
            this.activeOffer = e;
            this.onOpenModal();
        },

        handleScrollToForm() {
            this.$gsap.to(window, {
                duration: .5,
                scrollTo: '#home-form',
            });
        },

        onOpenModal() {
            const props = {
                offers: this.offersData,
                socials: this.socials.socials,
                activeTab: this.activeOffer,
                isModal: true,
                fullscreen: true,
            };

            this.$modal.open(SubscribeForm, props);

            this.$modal.event.$on('change', e => {
                this.activeOffer = e;
                this.$modal.update({ ...props, activeTab: e });
            });
        },

        scrollToId(item) {
            const headerHeight = remToPx(scssVars[this.$deviceIs.pc ? 'header-h' : 'header-h-mobile']);
            const offset = item.includes('purpose') ? window.innerHeight * -1 : headerHeight;

            scrollToWithBlackout(item, offset);
        },
    },
};
