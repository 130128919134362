
export default {
    name: 'VTag',

    props: {
        /**
         * Определяет классы, которые будут модифицировать размер
         */
        size: {
            type: String,
            default: 'small',
            validator: value => ['small'].includes(value),
        },

        tag: {
            type: Object,
            default: () => ({
                label: '',
                value: '',
            }),
        },

        /**
         * Определяет классы, которые будут модифицировать цвет
         */
        color: {
            type: String,
            default: 'grey',
            validator: value => ['grey'].includes(value),
        },

        active: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classList() {
            return {
                [this.$style._active]: this.active,
                [this.$style._disabled]: this.disabled,
                [this.$style[`_${this.size}`]]: this.size,
                [this.$style[`_${this.color}`]]: this.color,
            };
        },
    },
};
