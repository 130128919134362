// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VTag_u-fUL{align-items:center;border:1px solid #0000;border-radius:.6rem;cursor:pointer;display:flex;position:relative;text-transform:uppercase;transition:color .3s ease,background-color .3s ease,border-color .3s ease,opacity .3s ease;-webkit-user-select:none;user-select:none;white-space:nowrap;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.VTag_u-fUL._disabled_\\+cWLc{pointer-events:none}.VTag_u-fUL._small_4TnCm{font-size:1rem;font-weight:500;height:2.7rem;line-height:1.16rem;padding:.8rem .72rem;text-transform:uppercase}.VTag_u-fUL._grey_c1fSC{background-color:#0000;border-color:#848286;color:#b6b4b7}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.VTag_u-fUL._grey_c1fSC:hover{background-color:#3a363d;border-color:#3a363d;color:#fff}}.VTag_u-fUL._grey_c1fSC._active_m2hRN{background-color:#150c25;border-color:#9971ff;color:#9971ff}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.VTag_u-fUL._grey_c1fSC._active_m2hRN:hover{background-color:#150c25;border-color:#c0a6ff;color:#c0a6ff}}.VTag_u-fUL._grey_c1fSC._active_m2hRN._disabled_\\+cWLc{color:#b6b4b7}.VTag_u-fUL._grey_c1fSC._disabled_\\+cWLc{border-color:#3a363d;color:#3a363d}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "5.2rem",
	"footer-h": "12.2rem",
	"VTag": "VTag_u-fUL",
	"_disabled": "_disabled_+cWLc",
	"_small": "_small_4TnCm",
	"_grey": "_grey_c1fSC",
	"_active": "_active_m2hRN"
};
module.exports = ___CSS_LOADER_EXPORT___;
